export interface GameState {
    game: Game,
    step: Step,
    players: Player[]
    extra: any,
    yourFrontendId: string,
}

export interface Game {
    rounds: number,
    roundDuration: number,
    excludedLetters: string[],
    categories: string[],
    creativityPointsPerRound: number,
    creativityPointValue: number,
    gameId: string,
    currentRound: number,
    over: boolean
}

export enum Step {
    LOBBY = "LOBBY",
    START = "START",
    FILL_CATEGORIES = "FILL_CATEGORIES",
    WAIT_FOR_ANSWERS = "WAIT_FOR_ANSWERS",
    VALIDATE_CATEGORIES = "VALIDATE_CATEGORIES",
    END = "END"
}

export interface Player {
    name: string,
    frontendId: string,
    lastPoll: number
}

export interface PlayerScore {
    player: Player,
    roundPoints: number[]
}

export interface CategoryAnswer {
    category: string,
    userAnswers: Answer[]
}

export interface Answer {
    player: Player,
    category: string,
    answer: string,
    points: number
    status: AnswerStatus,
    answerId: string
    creativityPlayerFrontendIds: [];
}

export enum AnswerStatus {
    GREEN,
    YELLOW,
    RED
}

export interface CategoryStat {
    category: string,
    list: number[]
}

export interface PointCategories {
    player: string,
    pointsPerCategory: number[]
}

export interface PlayerStats {
    playerName: string,
    receivedVetos: number,
    givenVetos: number,
    validAnswers: number,
    avgAnswerLength: string,
    minAnswerLength: number,
    maxAnswerLength: number
}
