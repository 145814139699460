import React, {useState} from 'react';
import '../common/Game.css';
import ValidateCategories from "./ValidateCategories";
import Letter from "../letter/Letter";
import Clock from "../clock/Clock";
import DoneButton from "./done-button/DoneButton";
import PlayerList from "../player-list/PlayerList";
import {GameState} from "../../entity/GameState";
import RoundIndicator from "../round-indicator/RoundIndicator";
import CreativityPointCounter from "./creativity-point-counter/CreativityPointCounter";

type ValidateCategoriesPageProps = { callback: Function, timeOffset: number, nextPoll: number, sessionId: string, gameState: GameState }
const ValidateCategoriesPage = (props: ValidateCategoriesPageProps) => {

    const [userFinished, setUserFinished] = useState<boolean>(false);
    const [creativityPointsSpent, setCreativityPointsSpent] = useState<number>(0);

    const finishClicked = () => {
        setUserFinished(true);
        props.callback();
    }

    const creativityPointSpent = () => {
        setCreativityPointsSpent(creativityPointsSpent + 1);
        props.callback();
    }

    const creativityPointRevoked = () => {
        setCreativityPointsSpent(creativityPointsSpent - 1);
        props.callback();
    }

    return <div id="game">
        <div id="play">
            <ValidateCategories sessionId={props.sessionId}
                                answers={props.gameState.extra.answers}
                                userFinished={userFinished}
                                allCreativityPointsSpent={creativityPointsSpent === props.gameState.game.creativityPointsPerRound}
                                creativityPointValue={props.gameState.game.creativityPointValue}
                                pointSpent={creativityPointSpent}
                                pointRevoked={creativityPointRevoked}
                                ownFrontendId={props.gameState.yourFrontendId}
                                players={props.gameState.players}
                                pollFunc={props.callback}/>
        </div>
        <div id="info-bar">
            <div id="info-wrapper">
                <Letter current={props.gameState.extra.letter}/>
                <Clock nextPoll={props.nextPoll}
                       callback={props.callback}
                       visibleTimer={false}/>
                {props.gameState.game.creativityPointValue > 0 && !userFinished ?
                    <CreativityPointCounter spent={creativityPointsSpent}
                                            total={props.gameState.game.creativityPointsPerRound}/> : ""}
                <DoneButton sessionId={props.sessionId} finishClicked={() => finishClicked()}/>
                <PlayerList players={props.gameState.players} readyPlayers={props.gameState.extra.readyPlayers}
                            currentStep={props.gameState.step} timeOffset={props.timeOffset}
                            sessionId={props.sessionId}/>
                <RoundIndicator current={props.gameState.game.currentRound} total={props.gameState.game.rounds}/>
            </div>
        </div>
    </div>;
}

export default ValidateCategoriesPage;
