import React, {useEffect, useState} from 'react';
import './Clock.css';
import {calculateDistanceInSecs} from "../../utils/Calculator";

type ClockProps = { nextPoll: number, visibleTimer: boolean, callback: Function }
const Clock = (props: ClockProps) => {

    const [seconds, setSeconds] = useState(calculateDistanceInSecs(props.nextPoll));

    useEffect(() => {
        setSeconds(calculateDistanceInSecs(props.nextPoll));
        const timer = setInterval(() => {
            setSeconds(seconds - 1);
            if (seconds <= 1) {
                props.callback();
            }
        }, 1000);
        return () => clearInterval(timer);
    });

    return <div id="clock"
                className={props.visibleTimer ? "visible-timer" : "hidden-timer"}>{seconds < 1 ? 0 : seconds} s</div>;
}

export default Clock;
