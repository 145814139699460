import React from 'react';
import './Home.css';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Home = () => {
    const {t} = useTranslation();

    return <div id="home">
        <h1>{t('page.home.headline1')}</h1>
        <p>{t('page.home.text1-1')} <i>{t('page.home.text1-2')}</i> {t('page.home.text1-3')}</p>
        <p>{t('page.home.text2')} 😃</p>
        <br/>
        <br/>
        <Link to={`/${t('navigation.newGame-path')}`} id="create-game-button"
              className="button-link">{t('page.home.button-text')}</Link>
        <br/>
        <br/>
        <h2>{t('page.home.headline2')}</h2>
        <p>{t('page.home.text3-1')} <Link
            to={`/${t('navigation.newGame-path')}`}>{t('page.home.link-text')}</Link> {t('page.home.text3-2')}</p>
        <p>{t('page.home.text4')}</p>
        <p>{t('page.home.text5')}</p>
        <p><b>{t('page.home.text6-hint')}</b>: {t('page.home.text6')}</p>
        <p>{t('page.home.text7-1')} <i>{t('page.home.text7-2')}</i>{t('page.home.text7-3')}</p>
    </div>;
}

export default Home;
